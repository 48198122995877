window.core = core = require('./Core');
var $ = require('jquery');
var Navigation = require('./components/Navigation/Navigation');
var Slider = require('./components/Slider/Slider');
var Form = require('./components/Forms/Form');
var ScrollButton = require('./components/ScrollButton/ScrollButton');
var Products = require('./components/Products/Products');
var Map = require('./components/Map/Map');
var NewsBlocks = require('./components/NewsBlocks/NewsBlocks');
var General = require('./components/General/General');
var slickMobile = false;

core.map = Map;

/**
 * document ready functions
 */
core.$document.ready(function() {
    new Navigation({
        selector: 'nav'
    });
    
    new Form();

    if(core.$window.width() > 1024 ) {
        new ScrollButton();
    }

    new Slider('[data-slider="text"]');

    new Slider('[data-slider="sponsors"]',{
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    new Slider('[data-slider="gallery"]',{
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    new Products();

    if (core.$window.innerWidth() <= 768) {
        slickMobile = new Slider('[data-slider="mobile"]');
    }

    new NewsBlocks();

    new General();
});

/**
 * document resize functions
 */
core.$window.resize(function() {
    if (core.$window.innerWidth() <= 768) {
        if (!slickMobile) {
            slickMobile = new Slider('[data-slider="mobile"]');
        }
    } else {
        if (slickMobile) {
            slickMobile.destroy();
            slickMobile = false;
        }
    }
});
